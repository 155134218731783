@import 'antd/lib/style/themes/default.less';
@import 'antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');
@import './styles';

html,
body {
  font-family: @font-family;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

svg {
  fill: currentColor;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0.7em;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background-color: @background-color;
  border-radius: 4px;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}
.ant-row {
  margin-bottom: 16px;

  &.-accounts,
  &.-fulfillment-types,
  &.-product-list,
  &.-modifier-group-list,
  &.-category-list,
  &.-category-row,
  &.-modifier-list,
  &.-special-availability,
  &.-modifier-group {
    margin-bottom: 0px;
  }
}

.rate-cards .label {
  font-size: 14px;
}

.rate-cards .label::before {
  width: 0;
}

.rate-cards .label span {
  padding-left: 0px;
}

.rate-cards .card {
  width: 200px;
  margin-bottom: 16px;
}

.rate-cards .card .name {
  margin-bottom: 16px;
}

.rate-cards .card .button.-delete {
  background: black;
  color: white;
}

.rate-cards .card .button.-view {
  font-size: 12px;
  padding: 0;
}

.rate-cards .button.-view {
  font-size: 12px;
  padding: 0;
}

.rate-cards .add-rate-card {
  margin-top: 16px;
}

.rate-cards .type-caption {
  margin-right: 5px;
}

.ant-drawer-header {
  background: @secondary-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-radius: 0;
}

.ant-drawer-title {
  color: @white;
  font-size: 24px;

  &.-secondary {
    color: @secondary-color
  }
}

.ant-drawer-header-no-title {
  > .ant-drawer-close {
    background: @secondary-color;
    padding: 8px;
    position: relative;
    margin: 8px 8px 8px auto;
    border-radius: 4px;
  }
}

.ant-drawer-close {
  color: @white;
  background: @primary-color;
  padding: 8px;
  position: relative;
  border-radius: 4px;
}

.ant-drawer-body {
  padding: 8px 24px;
}

//did the code below so the hover(trigger) area of close button for the tooltip is the whole button itself
//discounts-drawer is a custom class to reinforce style exclusivity
.discounts-drawer .ant-drawer-header {
  > .ant-drawer-close {
    position: relative;

    min-height: 33.75px;
    min-width: 33px;
  }

  //close-icon is a custom class to reinforce style exclusivity
  > .ant-drawer-close > .close-icon.anticon.anticon-close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

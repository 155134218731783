.ant-badge-status-dot {
  top: -4px;
}

.badge-text {
  line-height: 1;
}

.badge-text.-lg {
  font-size: @font-size-lg;
}

.badge-text.-xxxl {
  font-size: @font-size-xxxl;
}

.badge.-padding {
  padding-left: 12px;
}

.badge.-margin {
  margin-right: 16px;
}

.ant-popover-buttons {
  .ant-btn-ghost {
    color: @secondary-color;
    border-radius: @border-radius-md;
    border-color: @secondary-color;

    &:hover {
      border-color: @primary-color;
      color: @primary-color;
      background-color: transparent;
    }
  }

  .ant-btn-primary {
    background: @secondary-color;
    border-radius: @border-radius-md;
    border-color: @secondary-color;

    &:hover {
      background: @primary-color;
      border-color: @primary-color;
    }
  }
}

.ant-checkbox-wrapper {
  .ant-checkbox-inner {
    border-color: @background-color_75;
  }

  &.ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      border-color: @secondary-color;
      background-color: @component-background;

      &::after {
        border: none;
        transform: none;
        width: 8px;
        height: 8px;
        border-radius: 1px;
        background: @secondary-color;
        top: 3px;
        left: 3px;
      }
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner::after {
      background: @secondary-color;
      height: 2px;
      top: 6px;
      left: 3px;
      transform: none;
    }
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: @secondary-color;
    }
  }

  .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: @secondary-color;
  }

  .ant-checkbox-checked::after {
    border-color: @secondary-color;
  }
}

.ant-checkbox-group.-vertical {
  display: inline-grid;
  margin-right: 0;
}

// ant Tag/Basic
.ant-select-multiple .ant-select-selection-item {
  padding: 4px 8px;
  background: @background-color_50;
  align-items: center;

  color: @subtitle-color;
  font-size: @font-size-sm;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;

  .ant-select-selection-item-remove {
    display: inline-flex;
  }
}

.ant-tag.product-allergen {
  padding: 1px 8px;
  background: @background-color_25;
  align-items: center;
  border: none;
  height: 20px;
  box-sizing: border-box;

  color: @subtitle-color;
  font-size: @font-size-sm;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
}

// Ant Tag/Colorful
.ant-tag {
  padding: 4px 8px;
  background: transparent;
  border: 1px solid @background-color_75;
  border-radius: @border-radius-md;

  font-size: @font-size-sm;
  line-height: 1.5;
  text-transform: capitalize;
}

.ant-tag.-basic {
  border: 0;
  color: @text-color;
  background: @background-color_25;
  font-size: 12px;

  > span:first-child {
    margin-right: 6px;

    font-weight: 500;
    line-height: 1.5;
  }

  > span:last-child {
    position: relative;
    top: -1px;
  }
}

.ant-tag.-plain_bg {
  background: @white;
}

.ant-tag-blue {
  color: @tag-blue;
}

.ant-tag-green {
  color: @tag-green;
}

.ant-tag-red {
  color: @tag-red;
}

.ant-tag-orange {
  color: @tag-orange;
}

.ant-tag-magenta {
  color: @tag-magenta;
}

.ant-tag {
  > .order-count {
    font-weight: 300;
    color: @text-color-secondary;
  }
}

.ant-descriptions-title {
  color: @primary-color;
  font-size: @font-size-md;
}

.ant-descriptions-item-content {
  color: @text-color;
}

.ant-descriptions-item-label {
  color: @subtitle-color;
}

.ant-descriptions.-print {
  .ant-descriptions-item {
    padding-bottom: 2px;
  }

  .ant-descriptions-item-content {
    color: @black;
    margin-bottom: 16px;
  }

  .ant-descriptions-title {
    text-transform: uppercase;
    color: @black;
    margin-bottom: 8px;

    &::after {
      display: block;
      content: ' ';
      height: 2px;
      width: 36px;
      margin-top: 4px;
      background-color: black;
    }
  }
}
.table-text {
  color: @text-color;
  font-size: @font-size-base;
  font-weight: 400;
  line-height: 1.5;
}

// Table Head
.ant-table .ant-table-thead th.ant-table-cell {
  &.-plain {
    background-color: white;
    font-weight: 600;
  }

  background-color: @background-color_25;

  .table-text();
}


.ant-table .ant-table-tbody td.ant-table-cell {
  background-color: white;
  border-bottom: 1px solid @background-color_25;

  .table-text();
}

// ANTD Default Separator
th.ant-table-cell::before {
  display: none;
}

// ANTD Row Expand Icon
.ant-table-row-expand-icon {
  border: 0;
  background-color: transparent;

  &::before {
    top: 50%;
    left: 50%;

    height: 2px;
    width: 50%;
    border-radius: 1px;

    color: @subtitle-color;

    transform: translate(-30%, -50%) rotateZ(-45deg);
    transition: all 200ms;
  }

  &::after {
    top: 50%;
    right: 50%;

    height: 2px;
    width: 50%;
    border-radius: 1px;

    color: @subtitle-color;

    transform: translate(-70%, -50%) rotateZ(45deg);
    transition: all 200ms;
  }
}

.ant-table-cell button.ant-table-row-expand-icon-expanded {
  &::before {
    transform: translate(-30%, -50%) rotateZ(45deg);
  }

  &::after {
    transform: translate(-70%, -50%) rotateZ(-45deg);
  }
}

.ant-table-wrapper.rate-card {
  overflow-y: scroll;
  position: relative;

  .ant-table .ant-table-tbody td.ant-table-cell {
    word-break: keep-all;
  }

  .ant-table .ant-table-thead tr th.ant-table-cell {
    border-top: 1px solid #f3f2f4;
  }
  
  /*=======
  Vertical Table Head Start
  ========*/
  thead.ant-table-thead>tr>th.ant-table-cell:first-child {
    position: sticky;
    left: 0;
    z-index: 10;

    display: inline-block;
    width: 100%;
  }

  .ant-table-tbody>tr.ant-table-row.ant-table-row-level-0>td:first-child {
    position: sticky;
    left: 0;
    z-index: 10;

    display: inline-block;
    width: 100%;
    background-color: #f3f2f4;
  }
  /*=======
  Vertical Table Head End
  ========*/

  /*=======
  Table Body Start
  ========*/
  .ant-table .ant-table-thead tr th.ant-table-cell:not(:first-child) {
    background-color: #fafafa;
  }

  .ant-table-tbody>tr.ant-table-row.ant-table-row-level-0:first-child>td:not(:first-child) {
    background-color: #fafafa;
  }
  /*=======
  Table Body End
  ========*/
}
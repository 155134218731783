.kpi-table {
  width: 100%;
  margin-bottom: 80px;
}

.kpi-table td.kpi-header {
  border-bottom: 2px solid black;
  text-align: right;
  padding: 4px;
  font-weight: bold;
}

.kpi-table td {
  text-align: right;
  padding: 8px;
}

.kpi-table td.kpi-total {
  border-top: 1px solid black;
  font-weight: bold;
}

.kpi-table .kpi-description {
  text-align: left !important;
}

.kpi-table .kpi-description.subitem {
  text-align: left !important;
  padding-left: 24px;
}

.stampapp.-show {
  opacity: 0.8;
  display: table-cell;
}

.stampapp.-hide {
  display: none;
}

.kpi-table .kpi-subheader {
  text-decoration: underline;
  text-align: left;
}

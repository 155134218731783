@menu-item-active-bg: @secondary-color;
@menu-item-color: @text-color;
@menu-inline-toplevel-item-height: 54px;
@menu-item-height: 54px;
@menu-item-group-height: @line-height-base;
@menu-collapsed-width: 66px;
@menu-icon-margin-right: 24px;
@menu-item-font-size: @font-size-base + 2px;
@menu-icon-size: 18px;

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}

.ant-menu {
  font-size: @font-size-base;
}

.ant-menu > .ant-menu-item > .ant-menu-title-content > .anticon {
  margin-right: 8px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout {
  background: white !important;
}

.ant-layout .logo {
  margin: 24px 0;
  text-align: center;
}

.ant-layout .logo .brand {
  height: 60px;
  text-align: center;
}

.ant-layout-header {
  padding: 0 16px;
}

.ant-steps {
  padding: 0 16px;

  .ant-steps-item-container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }

  &:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical) .ant-steps-item {
    padding: 4px 0;
  }

  .ant-steps-item-title {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 600;
    padding: 0;
  }

  .ant-steps-item-title::after {
    top: -22px;
    left: 46px;
    height: 4px;
    z-index: -1;
  }

  .ant-steps-item-title::before {
    top: -22px;
    right: 46px;
    height: 4px;
    position: absolute;
    content: '';
    width: 9999px;
     z-index: -1;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::before {
    background: @secondary-color;
  }

  .ant-steps-item-finish:first-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::before {
    display: none;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background: @background-color_50;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::before {
    background: @secondary-color;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::before {
    background: @background-color_50;
  }

  &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 0;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background: white;
    border: 2px solid @primary-color;
    box-shadow: 0px 0px 0px 4px lighten(@primary-color, 20%);
    box-sizing: border-box;

    .ant-steps-icon {
      color: @primary-color;
      font-weight: 600;
    }
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background: @background-color_25;
    border: 2px solid @background-color_50;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background: @secondary-color;
    border-color: @secondary-color;

    svg {
      color: white;
    }
  }

  .ant-steps-item-icon {
    width: 42px;
    height: 42px;
    line-height: 40px;
    margin: 0;
  }

  .ant-steps-item-tail {
    left: 4px;
  }

  &.ant-steps-vertical {
    .ant-steps-item-container {
      align-items: flex-start;
    }

    .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
      top: 6px;
      left: 20px;
    }

    .ant-steps-item-content {
      margin: 0 0 0 32px;
    }
  }
}


.ant-picker {
  border-radius: 4px;
  height: 36px;
  
  .ant-picker-range-separator {
    border-top: 1px solid @background-color;

    span {
      display: none;
    }
  }

  .ant-picker-input ~ .ant-picker-input > input {
    text-align: right;
  }

  &:hover, .ant-picker-focused {
    border-color: @secondary-color;
  }
}

.ant-picker-dropdown {
  .ant-picker-cell-selected > .ant-picker-cell-inner{
    border-radius: 100%;
    color: white;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-radius: 100%;
    border: 1px solid @secondary-color;
    box-shadow: 0px 0px 0px 4px rgba(109, 118, 236, 0.2);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
    border-radius: 100%;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
    border-radius: 100%;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: @secondary-color;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: @background-color_25;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: @background-color_25;
  }

  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    background: @background-color_25;
    border: 1px solid @secondary-color;
    border-radius: 100%;
  }

  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after  {
    left: 0;
    right: 0;
  }
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: @background-color_10;
  border-radius: 100%;
}

.ant-picker-focused {
    border-color: @secondary-color;
    box-shadow: 0px 0px 0px 4px rgba(109, 118, 236, 0.2);
}

.ant-picker-range .ant-picker-active-bar {
  background: @secondary-color;
}

.ant-picker-cell-disabled::before {
  background: @background-color_10;
  color: @background-color;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: @secondary_10;
}

.ant-picker {
  &.-error {
    border: 1px solid @error-color;
  }
}

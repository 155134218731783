@import '../../styles/variables.less';

.pvtUi, table.pvtTable {
  font-family: @font-family;
}

.pvtAxisContainer, .pvtVals {
  border: 1px solid #eaeaea;
  background: none;
}

.pvtTotal, .pvtTotalLabel {
  // display: none;
}

// table.pvtTable thead, table.pvtTable thead tr th, table.pvtTable tbody tr th {
//   background-color: white;
//   border: 1px solid #c8d4e3;
//   font-size: 8pt;
//   padding: 5px;
//   font-weight: bold;
// }
.ant-switch {
  background-color: @white;
  box-shadow: 0 0 0 1px @secondary-color;
}

.ant-switch:focus, .ant-switch:focus:hover {
  box-shadow: 0 0 0 1px @secondary-color;
}

.ant-switch-checked {
  background-color: @secondary-color;
  box-shadow: none;

  > .ant-switch-handle::before {
    background-color: white;
  }
}

.ant-switch-handle {
  top: 3px;
  left: 3px;
  width: 16px;
  height: 16px;

  &::before {
    box-shadow: none;
    background-color: @secondary-color;
  }
}

.ant-switch-disabled {
  background: @disabled-color;
  box-shadow: none;
  opacity: 1;

  > .ant-switch-handle::before {
    background-color: white;
  }
}

.ant-switch-loading-icon {
  top: -5px;
}

.ant-switch-small {
  .ant-switch-handle {
    top: 50%;
    transform: translateY(-50%);
  }
}
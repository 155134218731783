.login-container {
  display: flex;
  background: #141323;
  color: white;
  height: 100vh;
  align-items: center;
}

.login-logo {
  text-align: center;
  margin-bottom: 24px;
}

.login-logo img {
  max-width: 80px;
}

.login-form {
  max-width: 400px;
  margin: 0 auto;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.login-form .ant-checkbox-wrapper {
  color: white;
}
.copy-id > svg > path {
    fill: #1890ff;
}

.copy-success > svg > path {
    fill: #389e0d;
}

.not-applicable {
    color: rgba(0, 0, 0, 0.45);
}

.terms-controls {
    margin-bottom: 24px;
}

.terms-filter {
    min-width: 112px;
}
//no choice but to override z-index using this class provided by ANTD in order to accomodate different types of notification
.ant-notification-topLeft {
  z-index: @z-index-notification-top-left;
  left: 88px !important;
}

.ant-notification-topRight {
  z-index: @z-index-notification-top-right;
  right: 24px !important;
}

//archive-warning is custom class to enforce style exclusivity
.archive-warning.ant-notification-notice-closable {
  border-radius: @border-radius-md;
  border: none;
  background-color: @white;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));
  padding: 12px 16px;
  width: max-content;

  > .ant-notification-notice-close {
    top: 50%;

    transform: translateY(-50%);
  }
}

//icon-container is custom class to enforce style exclusivity
.archive-warning.ant-notification-notice-closable
  > .ant-notification-notice-content
  > .ant-notification-notice-with-icon
  > .ant-notification-notice-icon
  > .icon-container {
  background-color: @secondary-color;
  box-sizing: border-box;
  border-radius: @border-radius-md;
  padding: 8px 12px;
  margin-right: 16px;

  > .anticon.anticon-delete > svg {
    width: 20px;
    height: 18px;
  }

  > .anticon.anticon-delete > svg > path {
    fill: @white;
  }
}

.archive-warning.ant-notification-notice-closable
  > .ant-notification-notice-content
  > .ant-notification-notice-with-icon
  > .ant-notification-notice-message {
  margin-bottom: 0;
}

.archive-warning.ant-notification-notice-closable
  > .ant-notification-notice-content
  > .ant-notification-notice-with-icon
  > .ant-notification-notice-message
  > .ant-space
  > .ant-space-item
  > .ant-space.archive-content {
  margin: 0 16px;

  > .ant-space-item > .title {
    max-width: 40ch;
    overflow: hidden;
    
    font-size: 14px;
    color: @text-color;
    white-space:nowrap;
    text-overflow: ellipsis;

    margin: 0 0 -12px 0;
  }

  > .ant-space-item > .description {
    font-size: 12px;
    color: @text-color-secondary;
  }
}

.archive-warning.ant-notification-notice-closable
  > .ant-notification-notice-content
  > .ant-notification-notice-with-icon {
  > .ant-notification-notice-icon {
    top: 50%;
    transform: translateY(-50%);
  }
}

// .column-with-sorter is a custom class to reinforce style exclusivity
th.ant-table-cell.column-with-sorter.ant-table-column-has-sorters.column-with-sorter {
    min-width: 160px;
}

.column-with-sorter > .ant-table-column-sorters {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    padding: 16px 16px 16px 0px;
}

.column-with-sorter > .ant-table-column-sorters > .ant-table-column-sorter {
    position: absolute;
    top: 50%;
    right: 24px;

    transform: translateY(-50%);
}

.column-with-sorter > .ant-table-column-sorters > .ant-table-column-title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: inline-flex;
    align-items: center;
}

.column-with-sorter > .ant-table-column-sorters > .ant-table-column-title > div {
    position: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: inherit;
    align-items: inherit;
}
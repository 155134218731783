.ant-picker-cell .ant-picker-cell-inner.-has-orders,
.ant-picker-cell .ant-picker-cell-inner.-has-orders:hover {
  position: relative;

  > .dot {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: @primary-color;
    position: absolute;
    bottom: 2px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
  }
}
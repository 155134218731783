// Tooltip
@tooltip-color: @white;
@tooltip-bg: @secondary-color;
@tooltip-arrow-color: @tooltip-bg;

.ant-tooltip-inner {
  background: @secondary-color;
  padding: 12px 16px;

  > a {
    text-align: center;
    color: @white;
    display: flex;
    align-items: center;

    text-decoration: underline;
    text-underline-offset: 2px;
  }

  > span > a {
    color: @white;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  > a > .anticon  {
    margin-right: 4px;
  }
}

.ant-tooltip-inner .sidebar-tooltip {
  text-decoration: none;
}

.ant-tooltip-inner .anticon {
  width: 24px;
  height: 24px;
}

.anticon.anticon-question-circle {
  position: relative;
  bottom: 1px;

  color: @secondary_50;

  > svg {
    width: 14px;
    height: 14px;
  }
}

@input-font-size: @font-size-sm + 2px;
@input-box-shadow--active: 0px 0px 0px 4px rgba(109, 118, 236, 0.2);
@input-box-shadow--error: 0px 0px 0px 4px rgba(236, 109, 113, 0.2);

// Base/Plain Input style
.input-base-dimensions {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  height: 36px;
  border-color: @background-color_75;
  border-radius: @border-radius-md;

  &:focus,
  &:hover,
  &:active {
    border: 1px solid @secondary-color;
    border-right-width: 0;
    box-shadow: @input-box-shadow--active;
  }

  &:disabled {
    color: @background-color;
    background-color: @background-color_25;

    &:hover {
      border: 1px solid @background-color_75;
      box-shadow: none;
    }
  }

  &::placeholder {
    color: @subtitle-color;
  }
}

.ant-input {
  .input-base-dimensions();
}

// Input with Prefix Text/Icon (Number and some others)
.ant-input-wrapper {
  position: relative;

  font-size: @input-font-size;
  border-radius: @border-radius-md;
  transition: all 0.3s;

  .ant-input-group-addon {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 36px;
    padding: 0;
    border-top-left-radius: @border-radius-md;
    border-bottom-left-radius: @border-radius-md;
    background-color: transparent;
    border: 0;

    font-weight: 600;
    font-size: inherit;

    pointer-events: none;

    button.ant-btn-icon-only {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding-left: 8px;
      padding-top: 2px;
      border: 0;
      background-color: transparent;
    }
  }

  .ant-input {
    .input-base-dimensions();
    padding-left: 36px;
  }

  .ant-input[type='number'] {
    padding: 8px 0 8px 36px;
    width: 100%;
    border-top-left-radius: @border-radius-md;
    border-bottom-left-radius: @border-radius-md;

    &:hover,
    &:focus {
      .ant-input-wrapper {
        border: 1px solid @secondary-color;
      }
    }
  }
}

.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: @border-radius-md;
  border-bottom-right-radius: @border-radius-md;
}

//Input Prefix/Icon
.ant-input-group-addon {
  border: 1px solid @background-color_75;
  border-right: 0;
}

// Search/Password Input
.ant-input-affix-wrapper,
.ant-input-password {
  display: inline-flex;
  align-items: center;
  padding: 6px 8px 6px 0;
  border: 1px solid @background-color_75;
  border-radius: @border-radius-md;

  .ant-input {
    display: flex;
    align-items: center;

    height: 100%;
    font-size: @input-font-size;

    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  input[type='password'],
  input[type='text'] {
    margin: 0 0 0 0;
    padding: 0 4px 0 8px;

    &:hover {
      border: none;
      box-shadow: none;
    }
  }
}

.ant-input-affix-wrapper-disabled.ant-input-affix-wrapper {
  background-color: @background-color_25;

  &:focus,
  &:hover,
  &-focused {
    border-color: @background-color_75;
    box-shadow: none;
  }
}

// Input Hovered/Focused state
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: @secondary-color;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:hover {
  border: 1px solid @secondary-color;
  border-right-width: 0;
  border-radius: @border-radius-md;
  box-shadow: @input-box-shadow--active;
}

.ant-input-affix-wrapper:invalid {
  border: 1px solid #d092dd;
  box-shadow: @input-box-shadow--error;
}

.ant-input-prefix {
  padding: 0 2px 0 12px;
  margin: 0;
}

.ant-input-suffix {
  padding: 0 8px;
  margin: 0;
}

.ant-input-search-icon::before {
  border: 0;
}

// Input Error state
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper {
  border-color: @primary-color;
}

.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover,
.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
  border-color: @primary-color;
  box-shadow: @input-box-shadow--error;
}

//Helper Text
.ant-form-item-extra,
.ant-form-item-explain {
  margin: 6px 0 8px 0;
  color: #4a4a68;
  font-weight: 600;
  font-size: @helper-text-font-size;
}

.ant-input-number-handler-wrap {
  border-left: 1px solid @background-color_50;
}

// Input Number in Table
.ant-space-item {
  input[type='number'].ant-input {
    padding-right: 0;
  }
}

// Removes the default spinners of Input Number
.ant-input[type='number'] {
  &::-webkit-outer-spin-button, 
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type=number] {
  -moz-appearance: textfield;
}

.ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child {
  border-radius: @border-radius-md;
}

@radio-border-color: #cfccd4;
@radio-solid-checked-color: @secondary-color;
@radio-dot-color: @secondary-color;

@radio-button-checked-bg: @secondary-color;
@radio-button-hover-color: @secondary-color;
@radio-button-color: @secondary-color;
@radio-button-active-color: @white;

.ant-radio-inner {
  border: 1px solid @background-color_75;
  background-color: transparent;
}

.ant-radio-checked {
  > .ant-radio-inner::after {
    background-color: @secondary-color;
    top: 50%;
    left: 50%;

    transform: scale(1) translate(-50%, -50%);
    transition: all 0.15s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
}

.ant-radio-wrapper:hover,
.ant-radio:hover {
  > .ant-radio,
  > .ant-radio-inner {
    border-color: @secondary-color;
  }
}

.ant-radio-group {
  display: flex;

  text-align: center;

  > .ant-radio-button-wrapper {
    color: @secondary-color;
    background-color: white;
  }

  > .ant-radio-button-wrapper:hover {
    color: white;
    background-color: @background-color_75;
  }

  > .ant-radio-button-wrapper:focus-within {
    box-shadow: none;
  }

  > .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    color: @white;
    background-color: @secondary-color;
  }
}

.ant-radio-group.-left-aligned {
  text-align: left;
}

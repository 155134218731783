.ant-form-item {
  &.ant-row {
    &.-fulfillment-types, &.-special-availability {
      margin-bottom: 0px;
    }
  }
}

.ant-form-item.-mobile-app-store-url-save-button {
  .ant-form-item-label {
    visibility: hidden
  }
}

.ant-upload-picture-card-wrapper.avatar-uploader {
  > .ant-upload-select {
    width: 400px;
    height: 400px;
    background-color: @body-background;

    &:hover {
      background-color: @secondary_10;
    }
  }

  &.-square > .ant-upload-select,
  &.-large > .ant-upload-select {
    height: auto;
    width: 100%;
    padding: 8px;

    > .ant-upload {
      flex-direction: column;
    }

    > .ant-upload > .title {
      color: @secondary-color;
      font-weight: 400;
      margin-bottom: 8px;
    }

    > .ant-upload > .subheading {
      color: @secondary_75;
    }
  }

  &.-square > .ant-upload-select {
    aspect-ratio: 1/1;
    padding: 16px;

    > .ant-upload > .icon {
      font-size: 16px;
      margin-bottom: 8px;
    }

    > .ant-upload > .title {
      margin-bottom: 8px;
    }

    > .ant-upload > .subheading {
      font-size: @font-size-sm;
    }
  }

  &.-large > .ant-upload-select {
    aspect-ratio: 7/3;

    > .ant-upload > .icon {
      font-size: 28px;
      margin-bottom: 24px;
    }

    > .ant-upload > .title {
      font-size: @font-size-lg;
    }
  }

  > .ant-upload-select > .ant-upload > .ant-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .ant-upload-select > .ant-upload > .ant-avatar > img {
    height: 80%;
    width: 80%;
  }
}

.image-preview-container {
  margin-bottom: 8px;
  position: relative;
  padding: 8px;
  aspect-ratio: 1/1;
  border: 1px solid @border-color-base;
  border-radius: @border-radius-md;

  > .overlay:hover {
    opacity: 1;
  }

  > .overlay {
    opacity: 0;
    transition: all 150ms ease-in;
    position: absolute;
    background-color: rgba(@black, 0.6);
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
  }

  > .preview {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.image-preview-action {
  color: white;
}

//.app-settings is a custom class for reinforcing style exclusivity
.app-settings .ant-upload-picture-card-wrapper.avatar-uploader {
  > .ant-upload-select {
    width: 344px;
    height: 104px;
    background-color: @body-background;

    &:hover {
      background-color: @secondary_10;
    }
  }
}

.app-settings span.ant-upload > img {
  width: inherit;
  height: inherit;
}

.ant-statistic-title {
  font-weight: bold;
  color: #babbc0;
}

.ant-statistic {
  background: white;
  > .ant-statistic-content > .ant-statistic-content-value {
    color: @text-color;
  }

  > .ant-statistic-content > .ant-statistic-content-prefix {
    font-size: 16px;
  }

  > .ant-statistic-content > .ant-statistic-content-suffix {
    font-size: 12px;
    clear: left;
    display: block;
    margin-left: 0;
    color: #babbc0;
  }
}

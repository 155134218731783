.ant-btn {
  font-weight: 500;
}

.ant-btn:hover {
  color: @white;
  background: @secondary-color;
  border-color: @secondary-color;
}

.ant-btn-primary:hover {
  background: @primary-color;
  border-color: @primary-color;
}

.ant-btn.-default-secondary {
  background: @background-color_25;
  border-color: @background-color_25;
  color: @text-color;
}

.ant-btn-link {
  padding: inherit 0px;
  color: @white;

  &:hover {
    background: none;
    text-decoration: underline;
  }
}

.ant-btn-link.remove-section {
  padding: inherit 0px;
  border: none;
  padding-left: 0;
  color: @secondary-color;

  &:hover,
  &:active,
  &:focus {
    background: none;
    border: none;
    color: @tag-red;
  }

  &:hover > span:last-child,
  &:active > span:last-child,
  &:focus > span:last-child {
    box-shadow: 0 1px 0 @tag-red;
  }

  > span:last-child {
    padding-top: 2px;

    line-height: 1;
    font-weight: 400;
    text-transform: capitalize;

    box-shadow: 0 1px 0 @secondary-color;
  }
}

.ant-btn-link.-primary {
  padding: inherit 0px;
  border: none;
  padding-left: 0;
  color: @primary-color;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    background: none;
    border: none;
    color: @tag-purple;
  }

  &:hover > span:last-child,
  &:active > span:last-child,
  &:focus > span:last-child {
    box-shadow: 0 1px 0 @tag-purple;
  }

  > span:last-child {
    padding-top: 2px;

    line-height: 1;
    font-weight: 400;
    text-transform: capitalize;

    box-shadow: 0 1px 0 @primary-color;
  }

  &.-plain > span:last-child {
    box-shadow: none;
  }
}

.ant-btn .ant-btn-background-ghost:hover {
  color: @secondary-color;
  background: transparent;
  border-color: @secondary-color;
}

.ant-btn-primary .ant-btn-background-ghost:hover {
  color: @primary-color;
  background: transparent;
  border-color: @primary-color;
}

.ant-btn-icon-only.ant-btn-sm {
  width: 28px;
  height: 28px;
}

.ant-btn-icon-only {
  border-radius: @border-radius-md;
}

.ant-btn-ghost {
  border-color: @primary-color;
  color: @primary-color;
  border-radius: @border-radius-md;

  &:hover {
    border-color: @primary-color;
    color: @primary-color;
    background-color: transparent;
  }
}

.ant-btn-ghost.-secondary {
  border-color: @secondary-color;
  color: @secondary-color;
  border-radius: @border-radius-md;

  &:hover {
    border-color: @primary-color;
    color: @primary-color;
    background-color: transparent;
  }
}

.ant-btn.save {
  border-radius: @border-radius-md;
}

.ant-btn-dangerous {
  background-color: transparent;

  &.-borderless {
    border-color: transparent;
  }
}

.ant-btn-dangerous:hover {
  color: red;
  border-color: red;
  background-color: transparent;

  &.-borderless {
    border-color: transparent;
  }
}

.ant-btn-dangerous:focus {
  background-color: transparent;

  &.-borderless {
    border-color: transparent;
  }
}

[disabled].ant-btn-primary {
  background-color: @secondary_25;
  color: @secondary_50;
  border-radius: @border-radius-md;

  &:hover {
    background-color: @secondary_25;
    color: @secondary_50;
  }
}

.archive-warning.ant-notification-notice .ant-btn-ghost {
  padding: 8px 16px;

  font-size: @font-size-base;
  line-height: @font-size-base;
  text-transform: uppercase;
  color: @secondary-color;
  border-color: @secondary-color;
  border-radius: @border-radius-md;
  font-weight: 500;
}

button.ant-btn {
  text-transform: uppercase;
}

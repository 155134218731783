.ant-slider {

    .ant-slider-rail {
        background: @background-color_50;
        height: 2px;
    }

    .ant-slider-track {
        background: @secondary-color;
        height: 2px;

        &:hover {
            background: @secondary-color;
        }
    }

    .ant-slider-handle {
        width: 28px;
        height: 28px;
        margin-top: -13px;
        border: none;
        background: @component-background;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 1px rgba(0, 0, 0, 0.1);
        transition: background 0.5s;

        &.ant-tooltip-open {
            background-color: @secondary-color;
            transition: background 0.5s;
        }
    }

    .ant-slider-dot {
        width: 4px;
        height: 4px;
        background: @component-background;
        border: none;
        border-radius: unset;
    }

    &:hover {
        .ant-slider-track {
            background: @secondary-color;
        }
    }
}

.ant-slider-tooltip {
    .ant-tooltip-arrow {
        width: 1px;
        background: @secondary-color;
    }

    .ant-tooltip-inner {
        padding: 8px 16px;
        border-radius: 24px;
        opacity: 1;
        background: @secondary-color;
    }
}

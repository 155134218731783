.ant-tabs-tab {
    padding: 0.5rem 0.5rem;
    color: @subtitle-color;
    font-size: @navigation-font-size;
    font-weight: 500;

    :hover {
        color: @primary-color;
    }
}

.ant-tabs-ink-bar-animated,
.ant-tabs-ink-bar {
    transition: none;
    animation: inkBar 0.3s !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
        color: @secondary-color;
    }
}

.ant-tabs-ink-bar {
    background: @secondary-color;
}

@keyframes inkBar {
    from {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
}

.ant-tabs-top>.ant-tabs-nav {
    margin-bottom: 0.5rem;
}

/* needed this due to a bug in antd tabs,
   see https://github.com/ant-design/ant-design/issues/33050 for more info
*/
.ant-tabs-nav-operations {
    display: none !important;
}
// container of Base Select
.ant-select {
  color: @text-color;
  // nested container/borders
  &.ant-select:not(.ant-select-customize-input) > .ant-select-selector {
    border: 1px solid @background-color_75;
    padding: 2px 16px;
    border-radius: @border-radius-md;

    &:hover {
      border: 1px solid @secondary-color;
      box-shadow: @input-box-shadow--active;
    }
  }

  &.ant-select-single:not(.ant-select-customize-input) > .ant-select-selector {
    padding: 2px 16px;
    height: 36px;
    border-radius: @border-radius-md;

    &:hover {
      border: 1px solid @secondary-color;
      box-shadow: @input-box-shadow--active;
    }
  }

  > .ant-select-arrow {
    top: 50%;

    display: flex;
    align-items: center;
  }
}

.ant-select.ant-select-single.-error > .ant-select-selector {
  border: 1px solid @error-color;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  > .ant-select-selector {
  border-color: @secondary-color;
  box-shadow: @input-box-shadow--active;
}

.ant-select-single > .ant-select-selector > .ant-select-selection-search {
  left: 16px;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

//  ANTD Select Dropdown (Options list)
.ant-select-dropdown {
  border: 1px solid @background-color_75;
  box-sizing: border-box;
  z-index: 9999;

  box-shadow: 0px 2px 4px rgba(7, 4, 146, 0.1),
    0px 24px 60px rgba(6, 47, 125, 0.05), 0px 12px 24px rgba(27, 59, 119, 0.05);
  border-radius: 4px;
}

.ant-select-clear {
  display: flex;
}

//* Usage Guide: add the select-multiple class into the dropdownClassName property of ANTD's <Select />
.select-multiple .ant-select-item {
  position: relative;

  display: flex;
  align-items: center;

  &::before {
    position: absolute;
    top: 50%;
    left: 16px;

    display: block;
    content: '';
    width: 16px;
    height: 16px;
    border: 1px solid @background-color_75;
    border-radius: @border-radius-md;

    transform: translateY(-50%);
  }
}

.select-multiple
  div[aria-selected='true'].ant-select-item-option-selected:not(span[role='img']) {
  background-color: transparent;

  &::before {
    position: absolute;
    top: 50%;
    left: 16px;

    display: block;
    content: '';
    width: 16px;
    height: 16px;
    border: 1px solid @background-color_75;
    border-radius: @border-radius-md;

    transform: translateY(-50%);
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 20px;

    display: block;
    content: '';
    width: 8px;
    height: 8px;
    background-color: @secondary-color;
    border-radius: @border-radius-base;

    transform: translateY(-50%);
  }
}

/* .select-multiple
  > div
  > div
  > rc-virtual-list
  > rc-virtual-list-holder
  > div
  > rc-virtual-list-holder-inner
  > .ant-select-item-option */
.select-multiple .ant-select-item-option {
  > .ant-select-item-option-content {
    padding-left: 32px;
  }
}

.ant-select-item-option-state {
  > span[role='img'].anticon {
    display: none;
  }
}

div[aria-selected='false'].ant-select-item-option-active,
div[aria-selected='true'].ant-select-item-option-selected {
  background-color: @secondary_10;
  border-radius: 4px;
}

.ant-select-multiple.ant-select.ant-select:not(.ant-select-customize-input).-with-icon {
  & > .ant-select-selector {
    padding: 4px 8px 4px 48px;
  }
}

.ant-select-item-option.divider {
  cursor: default;
  padding: 0;
  min-height: unset;

  > .ant-select-item-option-content > .ant-divider {
    margin: 4px 0;
  }
}

//order-dropdown is a custom class to reinforce style exclusivity
.ant-select-dropdown.order-dropdown {
  //needed to override the width since it originally sets a fixed with according to the height of its parent via style prop
  width: 240px !important;

  .ant-select-item-option-content {
    display: flex;
  }
  //order-count is a custom class to reinforce style exclusivity
  span.order-count {
    margin-left: auto;

    color: @text-color-secondary;
    font-weight: 300;
    text-align: right;
  }
}

// fix for the select component having a slightly higher height than its sibling select components
.ant-select[data-testid='order-status-filter-dropdown'].ant-select-multiple.ant-select.ant-select:not(.ant-select-customize-input).-with-icon {
  .ant-select-selector {
    padding-bottom: 2px;
  }
}

.ant-select[data-testid='order-location-filter-dropdown'].ant-select-multiple.ant-select.ant-select:not(.ant-select-customize-input).-with-icon {
  .ant-select-selector {
    padding-bottom: 2px;
  }
}

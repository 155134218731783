@space-sizes: 0, 2, 4, 6, 8, 10, 12, 16, 24, 32, 40, 48, 56, 64, 72, 80;
@direction-margin: {
  mt: top;
  mr: right;
  mb: bottom;
  ml: left;
}

._w-100 {
  width: 100%;
}

._h-100 {
  height: 100%;
}

.max-content {
  width: max-content;
}

each(@space-sizes, {
  ._w-@{value} {
    width: unit(@value * 10, px);
  }
})

/*=======
  Margin
========*/
each(@space-sizes, {
  ._mt-@{value} {
    margin-top: unit(@value, px);
  }

  ._mr-@{value} {
    margin-right: unit(@value, px);
  }

  ._mb-@{value} {
    margin-bottom: unit(@value, px) !important;
  }

  ._ml-@{value} {
    margin-left: unit(@value, px);
  }

  ._my-@{value} {
    margin-top: unit(@value, px);
    margin-bottom: unit(@value, px);
  }

  ._mx-@{value} {
    margin-left: unit(@value, px);
    margin-right: unit(@value, px);
  }
})

each(@direction-margin, .(@value, @key) {
  ._@{key}-auto {
    margin-@{value}: auto;
  }
})

/*=======
  Alignment
========*/
._center-vertical {
  display: flex;
  align-items: center;
}

/*=======
  Displays
========*/
._inline-grid {
  display: inline-grid;
}

/*=======
  Padding
========*/
each(@space-sizes, {
  ._pt-@{value} {
    padding-top: unit(@value, px);
  }

  ._pr-@{value} {
    padding-right: unit(@value, px) !important;
  }

  ._pb-@{value} {
    padding-bottom: unit(@value, px);
  }

  ._pl-@{value} {
    padding-left: unit(@value, px) !important;
  }

  ._py-@{value} {
    padding-top: unit(@value, px);
    padding-bottom: unit(@value, px);
  }

  ._px-@{value} {
    padding-left: unit(@value, px);
    padding-right: unit(@value, px);
  }

  ._p-@{value} {
    padding: unit(@value, px);
  }
})

/*=======
  Text
========*/
._cl-primary {
  color: @primary-color;
}

._cl-text {
  color: @text-color;
}

._cl-text-secondary {
  color: @text-color-secondary;
}

.cl-text-warning {
  color: @warning-color;
}

._cl-text-error {
  color: @error-color;
}

._cl-text-success {
  color: @success-color;
}

._cl-white {
  color: white;
}

._cl-text-secondary-75 {
  color: @text-color-secondary_75;
}

._capitalize {
  text-transform: capitalize;
}

._uppercase {
  text-transform: uppercase;
}

a._underline {
  text-decoration: underline;
}

._link-white {
  color: white;

  &:hover {
    color: white;
  }
}

._text-right {
  text-align: right;
}

._link-primary {
  color: @primary-color;
}

._link-secondary {
  color: @secondary-color;
}

.btn-text {
  text-transform: uppercase;
  font-weight: 500;
}

._text-center {
  text-align: center;
}

._no-wrap {
  white-space: nowrap;
}

.settings-title {
  padding: 0 0 24px 0;

  .ant-page-header-heading-title {
    font-size: @font-size-base + 1;
    line-height: 1.5;
  }
}

.form-title {
  .ant-page-header-heading-title {
    font-size: @font-size-base + 1;
    line-height: 1.5;
  }
}

/*=======
  Font Weight
========*/
._text-weight-600 {
  font-weight: @font-weight-600;
}


/*=======
  Cursor
========*/

._pointer {
  cursor: pointer;
}

._no-pointer-events {
  pointer-events: none;
}

/*=======
  Background
========*/
._bg-gray-3 {
  background: @background-gray-3;
}

/*=======
  Border radius
========*/
._br-4 {
  border-radius: 4px;
}
